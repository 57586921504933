.media-gallery {
  @apply box-border overflow-hidden rounded-xl isolate relative w-full h-auto;
}

.media-gallery__item {
  @apply border-0 box-border block float-left relative rounded-xl overflow-hidden;

  &__icons {
    @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;

    .svg-icon {
      @apply h-24 w-24;
    }
  }
}

.media-gallery__item-thumbnail {
  @apply block relative text-gray-400 cursor-zoom-in z-10 w-full h-full;

  video {
    @apply w-full h-full object-cover;
  }
}

.media-gallery__preview {
  @apply bg-gray-200 dark:bg-gray-900 rounded-lg w-full h-full object-cover absolute top-0 left-0 z-0;

  &--hidden {
    @apply hidden;
  }
}

.media-gallery__gifv {
  @apply w-full h-full overflow-hidden relative;
}

.media-gallery__item-gifv-thumbnail {
  @apply rounded-md cursor-zoom-in h-full w-full top-0 z-10 object-cover relative transform-none;
}

.media-gallery__gifv__label,
.media-gallery__filename__label,
.media-gallery__file-extension__label {
  @apply block absolute bg-white leading-4 opacity-90 z-10 font-semibold text-xs rounded-sm bottom-1 left-1 px-0.5 py-1 pointer-events-none transition-opacity;
  background: rgba($base-overlay-background, 0.5);
}

.media-gallery__gifv {
  &.autoplay {
    .media-gallery__gifv__label {
      @apply hidden;
    }
  }

  &:hover {
    .media-gallery__gifv__label {
      @apply opacity-100;
    }
  }
}

$media-compact-size: 50px;

.media-gallery--compact {
  @apply bg-transparent;
  height: $media-compact-size !important;

  .media-gallery__item {
    @apply mr-1;
    width: $media-compact-size !important;
    height: $media-compact-size !important;
    inset: auto !important;
    float: left !important;

    &-overflow {
      @apply text-xl;
    }

    &__icons .svg-icon {
      @apply h-8 w-8;
    }
  }

  .media-gallery__file-extension__label {
    @apply hidden;
  }
}
